import React from "react";
import NotFoundHeader from "../components/Header/NotFoundHeader";

function NotFound() {
  return (
    <>
      <NotFoundHeader />
      <div className="not-found-container">
        <div className="not-found-content">
          <h1>Woah! </h1>
          <h1>404</h1>
          <p>Oops! The page you’re looking for doesn’t exist.</p>
          <a href="/" className="back-home-btn">Go Back Home</a>
        </div>
      </div>
    </>
  );
}

export default NotFound;
