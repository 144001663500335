import React from "react";
import { Nav, Logo, NavLink, Bars, NavMenu, NavBtn } from "./HeaderElements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const Header = ({ toggle }) => {
  return (
    <div className="Container" style={{ padding: 0 }}>
      <Nav>
        <Logo to="/">
          <img
            src="/siteimages/whitelogo.png"
            alt="logo"
          />
        </Logo>
        <NavMenu>
          <NavLink className="menu-item" to="experience">
            Experience
          </NavLink>
          <NavLink className="menu-item" to="about">
            About
          </NavLink>
          <NavLink className="menu-item" to="projects">
            Projects
          </NavLink>
          <NavLink className="menu-item" to="contact">
            Contact
          </NavLink>
          {/* <NavLink className="menu-item" to="/apps">
            <Link to="/apps">
              My Tools!
            </Link>
          </NavLink> */}
        </NavMenu>
        <NavBtn>
          <a
            className="btn ResumeBtn"
            href="https://sgonzalez-personal-bucket.s3.amazonaws.com/CV_SebastianGonzalezRamirez.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume <FontAwesomeIcon icon={faDownload} />
          </a>
        </NavBtn>
        <Bars onClick={toggle} />
      </Nav>
    </div>
  );
};

export default Header;
