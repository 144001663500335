import React from "react";
import { Nav, Logo, NavMenu, NavBtn, StyledNavLink } from "../AppHeader/AppHeaderElements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'



const NotFoundHeader = ({ toggle }) => {
  return (
    <main>
      <div className="Container" style={{ padding: 0 }}>
        <Nav>
          <Logo to="/">
            <img
              src="/siteimages/whitelogo.png"
              alt="logo"
            />
          </Logo>
          <NavMenu>
            <StyledNavLink className="menu-item" to='/'>
              ⇦ Home
            </StyledNavLink>
          </NavMenu>
          <NavBtn>
            <a
              className="btn ResumeBtn"
              href="https://sgonzalez-personal-bucket.s3.amazonaws.com/CV_SebastianGonzalezRamirez.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume <FontAwesomeIcon icon={faDownload} />
            </a>
          </NavBtn>
        </Nav>
      </div>
    </main>
  );

};

export default NotFoundHeader;
